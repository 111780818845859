body {
  font-family: 'Lato';
  position: relative;
}
body ul {
  list-style-type: none;
}
body h1 {
  margin: 0;
  color: #0f262c;
  font-size: 36px;
  font-weight: 900;
  font-style: italic;
}
body h1::after {
  content: "";
  display: block;
  height: 1px;
  width: 41px;
  background-color: #0f262c;
  margin: 8px 0 22px;
}
body .section-title {
  padding: 80px 0 75px;
}
body .section-title p {
  font-size: 16px;
  line-height: 24px;
  color: #768588;
}
header {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #000;
}
@media (min-width: 1024px) {
  header {
    height: 90px;
  }
}
header #logo {
  margin-top: 12.5px;
  display: inline-block;
}
header #logo .svg {
  height: 25px !important;
  width: auto !important;
}
@media (min-width: 1024px) {
  header #logo {
    float: left;
    height: 90px;
    margin-left: -45px;
    margin-top: 27px;
  }
  header #logo .svg {
    height: 37px !important;
  }
}
header #mobile-menu-toggler {
  float: right;
  height: 50px;
}
header #mobile-menu-toggler .svg {
  height: 15px;
  margin-top: 17.5px;
  display: inline-block;
  cursor: pointer;
}
@media (min-width: 1024px) {
  header #mobile-menu-toggler {
    display: none;
  }
}
header #lang-switch {
  float: right;
  line-height: 90px;
}
header #lang-switch a {
  line-height: 90px;
  color: #0f262c;
  transition: all 0.4s ease;
  font-size: 13px;
  font-weight: bold;
}
header #lang-switch a:hover {
  text-decoration: none;
}
header #lang-switch a.active {
  color: #0092af;
}
header #lang-switch a:first-child::after {
  content: "/";
  display: inline-block;
  color: #0f262c;
  padding: 0 3px;
}
@media (max-width: 1023px) {
  header #lang-switch {
    float: none;
    position: fixed;
    bottom: 40px;
    right: 22px;
    z-index: 30;
    display: none;
  }
}
header #menu {
  float: right;
}
header #menu ul {
  text-align: right;
}
header #menu ul li {
  display: inline-block;
  margin-right: 30px;
}
@media (min-width: 1200px) {
  header #menu ul li {
    margin-right: 35px;
  }
}
header #menu ul li.active a {
  color: #0092af !important;
}
header #menu ul li a {
  line-height: 90px;
  color: #0f262c;
  transition: all 0.4s ease;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
}
header #menu ul li a:focus,
header #menu ul li a:active,
header #menu ul li a:visited {
  background-color: transparent;
  color: #0f262c;
}
header #menu ul li a:hover {
  color: #0092af;
  text-decoration: none;
  background-color: transparent;
}
header #menu ul li:hover {
  background-color: transparent;
}
@media (max-width: 1023px) {
  header #menu {
    position: fixed;
    left: 0;
    top: 50px;
    float: none;
    height: calc(100vh - 50px);
    background-color: #fff;
    width: 100%;
    display: none;
  }
  header #menu ul {
    text-align: left;
    padding: 30px 0 0;
  }
  header #menu ul li {
    display: block;
    margin-right: 0;
  }
  header #menu ul li a {
    line-height: 75px;
  }
}
main {
  margin-top: 50px;
}
@media (min-width: 1024px) {
  main {
    margin-top: 90px;
  }
}
main #home {
  background-color: #cdcdcd;
}
@media (min-width: 1200px) {
  main #home {
    background: url("../img/banner.jpg") center right #cdcdcd no-repeat;
  }
}
main #home .container {
  position: relative;
  height: 550px;
}
main #home .container .text-wrapper {
  position: absolute;
  top: 50%;
  width: calc(100% - 40px);
  left: 15px;
  transform: translateY(-50%);
}
main #home .container .text-wrapper h2 {
  color: #fff;
  font-weight: 900;
  font-style: italic;
  font-size: 74px;
  margin: 0;
}
@media (max-width: 1023px) {
  main #home .container .text-wrapper h2 {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  main #home .container .text-wrapper h2 {
    font-size: 40px;
  }
}
main #home .container .text-wrapper p {
  font-size: 27px;
  color: #768588;
  line-height: 34px;
  font-style: italic;
  margin: 30px 0 55px;
}
@media (max-width: 1023px) {
  main #home .container .text-wrapper p {
    font-size: 23px;
  }
}
main #home .container .text-wrapper a {
  background-color: #fff;
  border-radius: 20px;
  line-height: 44px;
  display: inline-block;
  padding: 0 19px;
  font-size: 13px;
  color: #0f262c;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
}
main #about {
  height: 500px;
  background: url("../img/iwona.png") center;
  background-size: cover;
  overflow: hidden;
}
main #about .right-side {
  height: 500px;
  position: relative;
  background-color: rgba(12,28,32,0.89);
}
main #about .right-side::after {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 100%;
  background-color: rgba(12,28,32,0.89);
  position: absolute;
}
main #about .right-side::before {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 100%;
  background-color: rgba(12,28,32,0.89);
  position: absolute;
}
@media (min-width: 768px) {
  main #about .right-side::before {
    display: none;
  }
}
main #about .right-side .text-wrapper {
  position: relative;
  height: 500px;
}
main #about .right-side .text-wrapper .text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
main #about .right-side .text-wrapper .text h1 {
  color: #aef8ff;
}
main #about .right-side .text-wrapper .text h1::after {
  background-color: #fff;
}
main #about .right-side .text-wrapper .text .description {
  color: #fff;
  font-size: 16px;
  line-height: 27px;
}
main #about .right-side .text-wrapper .text .description p {
  margin: 0;
}
main #about .right-side .text-wrapper .text .description p:first-child {
  margin-bottom: 22px;
}
main #services .row > div {
  padding: 0;
  height: 215px;
  position: relative;
  margin-bottom: 3px;
}
main #services .row > div .service {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
main #services .row > div .service .svg {
  height: 65px;
}
main #services .row > div .service .description {
  color: #0f262c;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 30px;
}
main #services .row > div:nth-child(1) {
  background-color: #e6fdff;
}
main #services .row > div:nth-child(2) {
  background-color: #d0fbff;
}
main #services .row > div:nth-child(3) {
  background-color: #aef8ff;
}
main #visits #place {
  background: url("../img/gabinet.png") center;
  background-size: cover;
  overflow: hidden;
}
@media (min-width: 1200px) {
  main #visits #place {
    height: 505px;
  }
}
@media (min-width: 1200px) {
  main #visits #place .left-side {
    height: 505px;
  }
}
main #visits #place .left-side .text-wrapper {
  background-color: rgba(12,28,32,0.89);
  position: relative;
}
@media (min-width: 1200px) {
  main #visits #place .left-side .text-wrapper {
    height: 505px;
  }
}
main #visits #place .left-side .text-wrapper::before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 100vw;
  height: 100%;
  display: block;
  background-color: inherit;
}
@media (min-width: 768px) and (max-width: 1199px) {
  main #visits #place .left-side .text-wrapper::before {
    display: none;
  }
}
main #visits #place .left-side .text-wrapper .text {
  padding: 40px 0;
}
@media (min-width: 1200px) {
  main #visits #place .left-side .text-wrapper .text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
}
main #visits #place .left-side .text-wrapper .text h2 {
  color: #aef8ff;
  font-size: 36px;
  font-weight: 900;
  font-weight: 900;
  font-style: italic;
  margin: 0 0 10px;
}
main #visits #place .left-side .text-wrapper .text p {
  color: #fff;
  font-weight: 900;
  font-style: italic;
  font-size: 24px;
  line-height: 40.5px;
}
main #visits .right-side {
  position: relative;
}
main #visits .right-side .col-xs-12 {
  background-color: rgba(12,28,32,0.89);
  padding-bottom: 22px;
}
@media (min-width: 1200px) {
  main #visits .right-side {
    height: 505px;
  }
  main #visits .right-side .col-xs-12 {
    background-color: transparent;
    padding-bottom: 0;
    height: 505px;
    position: relative;
  }
}
main #visits .right-side .calendar-wrapper {
  padding: 30px 22px;
  background-color: rgba(12,28,32,0.89);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: middle;
}
@media (min-width: 550px) {
  main #visits .right-side .calendar-wrapper {
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 650px) {
  main #visits .right-side .calendar-wrapper {
    padding: 44px 84px;
  }
}
@media (min-width: 1200px) {
  main #visits .right-side .calendar-wrapper {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    width: 590px;
  }
}
main #visits .right-side .calendar-wrapper .days {
  margin-top: 40px;
}
@media (min-width: 550px) {
  main #visits .right-side .calendar-wrapper .days {
    padding-left: 74px;
    margin-top: 0;
  }
}
main #visits .right-side .calendar-wrapper .days .day .title {
  color: #aef8ff;
  font-style: italic;
  font-weight: 900;
  font-size: 18px;
  line-height: 33px;
  width: 65px;
  display: inline-block;
}
main #visits .right-side .calendar-wrapper .days .day .value {
  display: inline-block;
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  font-style: italic;
  line-height: 33px;
  letter-spacing: 1.3px;
}
main #contact #map iframe {
  height: 500px;
  width: 100%;
}
footer {
  margin-top: -4px;
  background-color: #0e2329;
  height: 100px;
}
footer #logo {
  margin-top: 33px;
}
footer #logo .svg {
  height: 40px;
}
