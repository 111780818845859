main-color = #0f262c
secondary-color = #0092af

body
    font-family: 'Lato'
    position: relative

    ul
        list-style-type: none

    h1
        margin: 0
        color: #0f262c
        font-size: 36px
        font-weight: 900
        font-style: italic

        &::after
            content: ""
            display: block
            height: 1px
            width: 41px
            background-color: #0f262c
            margin: 8px 0 22px

    .section-title
        padding: 80px 0 75px

        p
            font-size: 16px
            line-height: 24px
            color: #768588

header
    height: 50px
    position: fixed
    top: 0
    left: 0
    z-index: 10
    background-color: #fff
    width: 100%
    border-bottom: 1px solid #000

    @media( min-width: 1024px )
        height: 90px

    #logo
        margin-top: 12.5px
        display: inline-block

        .svg
            height: 25px !important
            width: auto !important

        @media( min-width: 1024px )
            float: left
            height: 90px
            margin-left: -45px
            margin-top: 27px

            .svg
                height: 37px !important

    #mobile-menu-toggler
        float: right
        height: 50px

        .svg
            height: 15px
            margin-top: 17.5px
            display: inline-block
            cursor: pointer

        @media( min-width: 1024px )
            display: none
            
    
    #lang-switch
        float: right
        line-height: 90px
        
        a
            line-height: 90px
            color: main-color
            transition: all 0.4s ease
            font-size: 13px
            font-weight: bold

            &:hover
                text-decoration: none

            &.active
                color: secondary-color

            &:first-child
                &::after
                    content: "/"
                    display: inline-block
                    color: main-color
                    padding: 0 3px

        @media( max-width: 1023px )        
            float: none;
            position: fixed;
            bottom: 40px;
            right: 22px;
            z-index: 30;
            display: none

    #menu
        float: right

        ul
            text-align: right
            li
                display: inline-block
                margin-right: 30px

                @media( min-width: 1200px )
                    margin-right: 35px

                &.active
                    a
                        color: secondary-color !important

                a
                    line-height: 90px
                    color: main-color
                    transition: all 0.4s ease
                    font-size: 13px
                    font-weight: bold
                    padding: 0

                    &:focus,
                    &:active,
                    &:visited   
                        background-color: transparent
                        color: main-color

                    &:hover                    
                        color: secondary-color
                        text-decoration: none    
                        background-color: transparent   

                &:hover
                    background-color: transparent            

        @media( max-width: 1023px )
            position: fixed
            left: 0
            top: 50px
            float: none
            height: calc( 100vh - 50px )
            background-color: #fff
            width: 100%
            display: none

            ul
                text-align: left
                padding: 30px 0 0

                li
                    display: block
                    margin-right: 0

                    a
                        line-height: 75px

main
    margin-top: 50px

    @media( min-width: 1024px )
        margin-top: 90px 
    
    #home
        background-color: #cdcdcd

        @media( min-width: 1200px )
            background: url('../img/banner.jpg') center right #cdcdcd no-repeat
        .container
            position: relative
            height: 550px

            .text-wrapper
                position: absolute
                top: 50%
                width: calc( 100% - 40px )
                left: 15px
                transform: translateY(-50%)

                h2
                    color: #fff
                    font-weight: 900
                    font-style: italic
                    font-size: 74px
                    margin: 0

                    @media( max-width: 1023px )
                        font-size: 50px

                    @media( max-width: 767px )
                        font-size: 40px

                p
                    font-size: 27px
                    color: #768588
                    line-height: 34px
                    font-style: italic
                    margin: 30px 0 55px

                    @media( max-width: 1023px )
                        font-size: 23px

                a
                    background-color: #fff
                    border-radius: 20px
                    line-height: 44px
                    display: inline-block
                    padding: 0 19px
                    font-size: 13px
                    color: #0f262c
                    text-transform: uppercase
                    font-weight: 900
                    text-decoration: none
    
    
    #about
        height: 500px
        background: url('../img/iwona.png') center
        background-size: cover
        overflow: hidden

        .right-side
            height: 500px
            position: relative
            background-color: rgba( #0c1c20 , 0.89 )

            &::after
                content: ""
                display: block
                width: 100vw
                height: 100%
                top: 0
                left: 100%
                background-color: rgba( #0c1c20 , 0.89 )
                position: absolute

            &::before
                content: ""
                display: block
                width: 100vw
                height: 100%
                top: 0
                right: 100%
                background-color: rgba( #0c1c20 , 0.89 )
                position: absolute

                @media( min-width: 768px )
                    display: none


            .text-wrapper
                position: relative
                height: 500px
                
                .text
                    position: absolute
                    top: 50%
                    left: 0
                    transform: translateY(-50%)

                    h1
                        color: #aef8ff

                        &::after
                            background-color: #fff

                    .description
                        color: #fff
                        font-size: 16px
                        line-height: 27px

                        p
                            margin: 0
                            &:first-child
                                margin-bottom: 22px

    #services
        .row
            > div
                padding: 0
                height: 215px
                position: relative
                margin-bottom: 3px

                .service
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    display: flex
                    flex-direction: row
                    align-items: center
                    justify-content: center

                    .svg
                        height: 65px

                    .description
                        color: #0f262c
                        font-size: 15px
                        line-height: 24px
                        text-transform: uppercase
                        padding-left: 30px
                
                &:nth-child(1)
                    background-color: #e6fdff

                &:nth-child(2)
                    background-color: #d0fbff

                &:nth-child(3)
                    background-color: #aef8ff

    #visits
        #place
            background: url('../img/gabinet.png') center
            background-size: cover
            overflow: hidden

            @media( min-width: 1200px )
                height: 505px

            .left-side
                @media( min-width: 1200px )
                    height: 505px

                .text-wrapper
                    background-color: rgba( #0c1c20 , 0.89 )
                    position: relative
                
                    @media( min-width: 1200px )
                        height: 505px

                    &::before
                        content: ""
                        position: absolute
                        right: 100%
                        top: 0
                        width: 100vw
                        height: 100%
                        display: block
                        background-color: inherit

                        @media( min-width: 768px ) and ( max-width: 1199px )
                            display: none

                    .text
                        padding: 40px 0

                        @media( min-width: 1200px )
                            position: absolute
                            top: 50%
                            transform: translateY(-50%)
                            padding: 0

                        h2
                            color: #aef8ff
                            font-size: 36px
                            font-weight: 900
                            font-weight: 900
                            font-style: italic
                            margin: 0 0 10px

                        p
                            color: #fff
                            font-weight: 900
                            font-style: italic
                            font-size: 24px
                            line-height: 40.5px

        
        .right-side
            position: relative

            .col-xs-12
                background-color: rgba( #0c1c20 , 0.89 )
                padding-bottom: 22px

            @media( min-width: 1200px )
                height: 505px 

                .col-xs-12
                    background-color: transparent
                    padding-bottom: 0
                    height: 505px
                    position: relative

            .calendar-wrapper
                padding: 30px 22px
                background-color: rgba( #0c1c20 , 0.89 )
                display: flex
                flex-direction: column
                align-items: center
                justify-content: middle

                @media( min-width: 550px )
                    flex-direction: row
                    align-items: center

                @media( min-width: 650px )
                    padding: 44px 84px

                @media( min-width: 1200px )
                    position: absolute
                    left: 60px
                    top: 50%
                    transform: translateY(-50%)
                    width: 590px

                .days
                    margin-top: 40px

                    @media( min-width: 550px )
                        padding-left: 74px
                        margin-top: 0

                    .day
                        .title
                            color: #aef8ff
                            font-style: italic
                            font-weight: 900
                            font-size: 18px
                            line-height: 33px
                            width: 65px
                            display: inline-block

                        .value
                            display: inline-block
                            color: #fff
                            font-weight: 300
                            font-size: 18px
                            font-style: italic
                            line-height: 33px
                            letter-spacing: 1.3px

    #contact
        #map
            iframe
                height: 500px
                width: 100%

footer
    margin-top: -4px
    background-color: #0e2329
    height: 100px

    #logo
        margin-top: 33px
        
        .svg
            height: 40px